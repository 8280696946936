<template>
    <el-dialog
        width="600px"
        top="40px"
        title="Markera som oidentifierad"
        :visible="visible"
        :close-on-click-modal="false"
        :before-close="resetFormAndClose"
        @closed="resetForm"
        :lock-scroll="false"
        @open="getPaymentDetails"
    >
        <div class="px-8" v-loading="$waiting.is('loading.payment_details')">
            <div>
                <table>
                    <tr>
                        <td class="">Kund namn</td>
                        <td class="pl-8">{{ paymentData.customerName }}</td>
                    </tr>
                    <tr>
                        <td class="">BG number</td>
                        <td class="pl-8">{{ paymentData.customerBgNumber }}</td>
                    </tr>
                    <tr>
                        <td class="">Klient adress</td>
                        <td class="pl-8">{{ paymentData.customerAddress }}</td>
                    </tr>
                    <tr>
                        <td class="">OCR</td>
                        <td class="pl-8">{{ paymentData.text }}</td>
                    </tr>
                    <tr>
                        <td class="">Belopp</td>
                        <td class="pl-8">{{ paymentData.amount | swedishNumberFormat }} {{ paymentData.originalCurrency }}</td>
                    </tr>
                    <tr>
                        <td class="">Datum</td>
                        <td class="pl-8">{{ formatDate(paymentData.bookingDate) }}</td>
                    </tr>
                </table>
            </div>
            <div class="pt-8">
                <el-form ref="form" :model="formData" :rules="rules">
                    <el-form-item prop="newComment">
                        <el-input v-model="formData.newComment" type="textarea" placeholder="Lämna en kommentar" resize="none" rows="5" />
                    </el-form-item>
                </el-form>
            </div>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button @click="closeModal" type="primary" :loading="$waiting.is('processing')">Stäng</el-button>
            <el-button @click="markAsUnidentified" type="primary" :loading="$waiting.is('processing')">Markera som oidentifierad</el-button>
        </span>
    </el-dialog>
</template>
<script>
import moment from "moment";
import Api from "../avstamning.api";

export default {
    props: {
        visible: {
            type: Boolean,
            default: false,
        },
        paymentId: {
            type: Number,
            default: null,
        },
    },

    watch: {
        visible() {
            if (this.visible) {
                this.getPaymentDetails();
            }
        },
    },

    data() {
        return {
            paymentData: {},
            formData: {
                newComment: "",
            },
            rules: {
                newComment: [{ required: true, message: "Nödvändig", trigger: "blur" }],
            },
        };
    },

    methods: {
        async getPaymentDetails() {
            this.$waiting.start("loading.payment_details");
            const data = await Api.getPaymentDetails(this.paymentId);
            this.paymentData = data;
            this.$waiting.end("loading.payment_details");
        },

        async markAsUnidentified() {
            await new Promise(resolve => this.$refs.form.validate(valid => valid && resolve()));

            this.$waiting.start("processing");
            await Api.addNewComment(this.paymentId, this.formData.newComment);
            await Api.markAsUnidentified(this.paymentId);
            this.$notify.success({ title: "Utfört" });
            this.$waiting.end("processing");
            this.$emit("refresh");
            this.resetFormAndClose();
        },

        resetFormAndClose() {
            this.resetForm();
            this.closeModal();
        },

        resetForm() {
            this.$refs.form.resetFields();
            this.formData.newComment = "";
            this.paymentData = {};
        },

        closeModal() {
            this.$emit("close");
        },

        formatDate(date) {
            return moment(date).format("YYYY-MM-DD");
        },
    },
};
</script>
